import React from 'react';
import { CircularProgress } from '@mui/material';

const Loader = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      flexDirection: 'column',
      gap: '1rem',
    }}
  >
    <CircularProgress color="inherit" />
    Loading...
  </div>
);

export default Loader;
